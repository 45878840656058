import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import OptionsCategory from '../../components/OptionsCategory'
import { getSubmitButtonTextForProductBasedViews } from '../../utils/testable/getSubmitButtonTextForProductBasedViews'
import { useOptionsConfigurationReducer } from './useOptionsConfiguration'

const OptionsConfiguration = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        viewType,
        currentView,
        optionsCategoriesToShow,
        productTypeFromData,
        availableProductCategories,
        currentPage,
        pagesList,
        disabledSubmit,
    } = useOptionsConfigurationReducer()

    const [userInfo] = useUserInfo()

    const categoryType = (productTypeFromData ? productTypeFromData.identifier : '') + '.' + viewType

    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.header.' + categoryType)}
            subHeader={getRoleAwareTranslationKey(
                userInfo,
                t(currentView + 'Strings.subheader.' + categoryType) !==
                    currentView + 'Strings.subheader.' + categoryType
                    ? currentView + 'Strings.subheader.' + categoryType
                    : '',
            )}
            submitButtonText={
                typeof getSubmitButtonTextForProductBasedViews(pagesList, currentPage, availableProductCategories) ===
                'string'
                    ? t(
                          getSubmitButtonTextForProductBasedViews(
                              pagesList,
                              currentPage,
                              availableProductCategories,
                          ) as string,
                      )
                    : t(currentView + 'Strings.goTo', {
                          productCategoryType: t(
                              (
                                  getSubmitButtonTextForProductBasedViews(
                                      pagesList,
                                      currentPage,
                                      availableProductCategories,
                                  ) as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).productCategoryType,
                          ),
                          selectionStep: t(
                              (
                                  getSubmitButtonTextForProductBasedViews(
                                      pagesList,
                                      currentPage,
                                      availableProductCategories,
                                  ) as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).selectionStep,
                          ),
                      })
            }
            tooltipText={
                t(
                    currentView +
                        'Strings.' +
                        'infoText' +
                        (productTypeFromData ? '.' + productTypeFromData.identifier : ''),
                ) ===
                currentView +
                    'Strings.' +
                    'infoText' +
                    (productTypeFromData ? '.' + productTypeFromData.identifier : '')
                    ? ''
                    : t(
                          currentView +
                              'Strings.' +
                              'infoText' +
                              (productTypeFromData ? '.' + productTypeFromData.identifier : ''),
                      )
            }
            viewType={currentView}
        >
            <>
                {optionsCategoriesToShow.length > 0 &&
                    optionsCategoriesToShow.map((value) => (
                        <OptionsCategory
                            key={value.categoryId + value.selected}
                            showTitles={optionsCategoriesToShow.length > 1}
                            {...value}
                        />
                    ))}
            </>
        </ViewWrapper>
    )
}

export default OptionsConfiguration
