import { createTheme, responsiveFontSizes, Theme } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import colors from './colors'
import fontStyles from './fontStyles'

const breakpoints = createBreakpoints({})
export const smallMobile = 410
export const smallestMobile = 409

const LocalTheme: Theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1440,
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Roboto Condensed',
        h1: { ...fontStyles.Abschnittsheadline },
        h2: { ...fontStyles.UbersichtTitel },
        h3: { ...fontStyles.ProduktboxProduktTitel },
        h4: { ...fontStyles.Abschnittssubline },
        h5: { ...fontStyles.SaveProgressTitle },
        h6: { ...fontStyles.FormTextSmall },
        body1: { ...fontStyles.body1 },
        body2: { ...fontStyles.body2 },
        subtitle1: { ...fontStyles.subtitle1 },
        subtitle2: { ...fontStyles.subtitle2 },
        button: { ...fontStyles.button },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: colors.header,
                borderBottom: `5px solid ${colors.midGray}`,
                boxSizing: 'content-box',
                height: '85px',
                [breakpoints.down('xs')]: {
                    height: '158px',
                },
            },
        },
        MuiButton: {
            text: {
                color: colors.white,
                '&.PortabilityViewAddButton': {
                    ...fontStyles.Body2,
                    fontFamily: 'Roboto',
                    color: colors.color1,
                },
                '&.FormButton': {
                    border: `2px solid ${colors.buttonPrimaryColor}`,
                    borderRadius: '5px',
                    padding: '16px 40px',
                    '&.BackButton': {
                        backgroundColor: colors.white,
                        color: colors.buttonPrimaryColor,
                        [breakpoints.down('xs')]: {
                            width: '100%',
                        },
                    },
                    '&.NextButton': {
                        backgroundColor: colors.buttonPrimaryColor,
                        [breakpoints.down('xs')]: {
                            width: '100%',
                            marginTop: '24px',
                        },
                    },
                    '&.GreenButton': {
                        backgroundColor: colors.green,
                        border: `2px solod ${colors.green}`,
                    },
                    '&$disabled': {
                        backgroundColor: colors.white,
                        border: `2px solid ${colors.lightGray}`,
                        color: colors.lightGray,
                    },
                    '&.OfferOptionButton': {
                        backgroundColor: 'transparent',
                        transition: '250ms',
                        border: `0px`,
                        color: colors.color1,
                        textAlign: 'center',
                        zIndex: 2,
                        [breakpoints.down('xs')]: {
                            padding: '16px 0px',
                            width: '100%',
                        },
                        '&.Active': {
                            backgroundColor: colors.green,
                            color: colors.white,
                        },
                    },
                },
                '&.SaveProgressButton': {
                    border: `2px solid ${colors.color1}`,
                    borderRadius: '5px',
                    padding: '9px 40px',
                    '&.NextButton': {
                        backgroundColor: colors.color1,
                    },
                    '&$disabled': {
                        backgroundColor: colors.white,
                        border: `2px solid ${colors.lightGray}`,
                        color: colors.lightGray,
                        '&.Send': {
                            color: colors.black,
                        },
                        '&.Loading': {
                            color: colors.black,
                        },
                    },
                },
                '&.SendVZFMailButton': {
                    '&$disabled': {
                        '&.Send': {
                            color: colors.black,
                        },
                        '&.Loading': {
                            paddingRight: 80,
                            color: colors.black,
                        },
                    },
                },
                '&.SendTouchpointButton': {
                    '&$disabled': {
                        '&.Send': {
                            color: colors.black,
                        },
                        '&.Loading': {
                            paddingRight: 80,
                            color: colors.black,
                        },
                    },
                },
            },
        },
        MuiButtonBase: {
            root: {
                '&.ProductCard': {
                    width: '100%',
                    '& .MuiBox-root': {
                        transition: '250ms all',
                    },
                    '& > .MuiGrid-root': {
                        '&.Selected': {
                            color: colors.white,
                        },
                    },
                },
                '&.OptionItemComponent': {
                    minHeight: '120px',
                    width: '100%',
                    borderRadius: '5px',
                    '& .MuiBox-root, & .MuiGrid-root': {
                        transition: '250ms all',
                    },
                    '& > .MuiGrid-root': {
                        minHeight: '120px',
                    },
                    '&$disabled': {
                        opacity: 0.4,
                    },
                },
                '&.ConfigurationItem': {
                    minHeight: '80px',
                    width: '100%',
                    borderRadius: '5px',
                    '& .MuiBox-root, & .MuiGrid-root': {
                        transition: '250ms all',
                    },
                },
            },
        },
        MuiCollapse: {
            root: {
                container: {
                    '&.ContactDataCollapse': {
                        marginTop: 16,
                    },
                },
            },
        },
        MuiDivider: {
            root: {
                '&.CostsTableDivider': {
                    backgroundColor: colors.color1,
                    marginBottom: '10px',
                    marginTop: '10px',
                },
                '&.SaveProgressDivider': {
                    backgroundColor: colors.white,
                    margin: 0,
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: 0,
                color: colors.color1,
                fontSize: '0.875rem',
                '& a': {
                    color: colors.color1,
                    textDecoration: 'underline',
                },
                '& .MuiCheckbox-root': {
                    alignSelf: 'start',
                    padding: 4,
                },
            },
            label: {
                marginLeft: 11,
            },
        },
        MuiFormLabel: {
            root: {
                color: colors.darkGray,
                '&.SaveProgressMailInput:': {
                    color: 'teal',
                },
            },
        },
        MuiGrid: {
            root: {
                '&.MobileProgressList': {
                    marginBottom: 27,
                },
                '&.HeaderComponentGrid': {
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'end',
                },
                '&.HeaderComponentHelpLink': {
                    alignItems: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'end',
                },
                '&.Selected': {
                    '& p, & a': {
                        color: colors.white,
                    },
                },
                '&.ProductCard': {
                    maxWidth: '100%',
                    minWidth: '160px',
                    margin: '0 auto',
                },
                '&.SwitchMonthGrid': {
                    backgroundColor: colors.lightGray,
                    borderRadius: '5px',
                    marginTop: '20px',
                    padding: '5px',
                    transition: '250ms',
                    zIndex: 1,
                    [breakpoints.down('xs')]: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    },
                    '& .MuiGrid-item:not(:first-child)': {
                        marginLeft: '8px',
                    },
                },
                '&.FooterLinkWrapper': {
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: 12,
                    [breakpoints.down('md')]: {
                        paddingRight: 24,
                    },
                    [breakpoints.down('sm')]: {
                        paddingRight: 0,
                        justifyContent: 'center',
                    },
                },
                '&.TouchpointsArea': {
                    marginTop: '30px',
                    '& .Title': {
                        color: colors.black,
                    },
                    '& .Subtitle': {
                        color: colors.black,
                    },
                    '& .TouchpointRadioWrapper': {
                        marginTop: '8px',
                    },
                    '& .ErrorBox': {
                        marginTop: 0,
                    },
                },
            },
            container: {
                '&.OptionItemPriceDescription': {
                    '& .OldPriceContainer': {
                        '& .OldPrice': {
                            '&::after': {
                                backgroundColor: colors.warning,
                            },
                            ...fontStyles.Body1,
                            fontFamily: 'Roboto',
                            // lineHeight: 1.125,
                        },
                    },
                    '& .NewPrice': {
                        ...fontStyles.Abschnittsheadline,
                        fontFamily: 'Roboto',
                        fontWeight: 900,
                    },
                    '& .InfoTooltipContainer': {
                        alignItems: 'flex-end',
                        '& svg': {
                            paddingBottom: '2.4px',
                        },
                    },
                    '& .PriceDescriptionText': {
                        ...fontStyles.subtitle1,
                        fontFamily: 'Roboto',
                        '& p': {
                            margin: 0,
                        },
                    },
                    '&.Selected': {
                        // backgroundColor: colors.color2,
                        '& p': {
                            color: colors.white,
                        },
                    },
                },
                '&.CostsTable': {
                    borderRadius: '5px',
                    '& .OldPrice': {
                        '&::after': {
                            backgroundColor: colors.green,
                        },
                        ...fontStyles.subtitle1,
                        fontFamily: 'Roboto',
                    },
                    '& .NewPrice': {
                        ...fontStyles.subtitle1,
                        fontFamily: 'Roboto',
                    },
                },
                '&.ProductTableRow': {
                    marginBottom: '16px',
                    '& .PriceContainer': {
                        justifyContent: 'flex-end',
                    },
                    '& .OldPriceContainer': {
                        '& .OldPrice': {
                            '&::after': {
                                backgroundColor: colors.green,
                                width: 'calc(100% + 4px)',
                                transform: `rotate(-10.7deg) translateY(-0.65rem)`,
                            },
                            ...fontStyles.body1,
                            fontFamily: 'Roboto',
                            whiteSpace: 'nowrap',
                        },
                    },
                    '& .NewPrice': {
                        ...fontStyles.body1,
                        fontFamily: 'Roboto',
                        whiteSpace: 'nowrap',
                    },
                    '& .PriceDescriptionText': {
                        ...fontStyles.Sternchentext,
                        textAlign: 'right',
                        '& p': {
                            margin: 0,
                        },
                    },
                    '& > .MuiBox-root': {
                        paddingRight: 16,
                        [breakpoints.down('xs')]: {
                            paddingRight: 0,
                        },
                    },
                },
                '&.Basket': {
                    flexDirection: 'column',
                    '& .CostsTable': {
                        borderRadius: '5px',
                        backgroundColor: colors.white,
                        '& .CostsTableTopDivider': {
                            borderBottom: `1px solid ${colors.color1}`,
                            borderTop: `1px solid ${colors.color1}`,
                            opacity: '1 !important',
                            marginBottom: '8px',
                        },
                    },
                },
                '&.Confirmation': {
                    backgroundColor: colors.green,
                    borderRadius: '5px',
                    padding: '24px',
                    '& .CostsTable': {
                        '& p, h5, h6': {
                            color: colors.white,
                        },
                        '& .OldPrice': {
                            '&::after': {
                                backgroundColor: colors.white,
                            },
                        },
                        // '& .CostsTableTopDivider': {
                        //     borderTop: `1px solid ${colors.white}`,
                        //     opacity: '1 !important',
                        //     marginBottom: '8px',
                        // },
                        '& .CostsTableDivider': {
                            backgroundColor: colors.white,
                            marginTop: '20px',
                            marginBottom: '20px',
                        },
                        '& .CostsTableSubtitle': {
                            marginBottom: '18px',
                            marginTop: '-6px',
                        },
                    },
                },
                '&.SaveProgressClickWrapper': {
                    '& > .MuiGrid-item:nth-child(1)': {
                        [breakpoints.down('xs')]: {
                            padding: 0,
                        },
                    },
                    '& .ErrorBox': {
                        marginTop: 0,
                        marginBottom: 20,
                    },
                },
                '&.OverviewTableRow': {
                    [breakpoints.down('xs')]: {
                        flexDirection: 'row',
                        marginBottom: '8px',
                    },
                },
                '& .OptionPriceContainer': {
                    '& .OldPrice': {
                        ...fontStyles.Body1,
                        fontFamily: 'Roboto',
                    },
                    '& .NewPrice': {
                        ...fontStyles.UbersichtTitel,
                        fontFamily: 'Roboto',
                        fontWeight: 900,
                    },
                    '& .BillMode': {
                        ...fontStyles.UbersichtTitel,
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                    },
                },
            },
            item: {
                '&.installationDetailsViewAppartmentData': {
                    flex: 1,
                    flexDirection: 'row',
                },
                '&.ProductCardTitleContainer': {
                    '& > .MuiBox-root': {
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        '& > .MuiBox-root': {
                            width: '32px',
                            height: '32px',
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    },
                },
                '&.ConfigurationItemTitleContainer': {
                    '& > .MuiBox-root': {
                        '& .ConfigurationItemRadio': {
                            borderRadius: '16px',
                        },
                        '& .ConfigurationItemCheckbox': {
                            borderRadius: '5px',
                        },
                    },
                },
                '&.OptionItemTitleContainer': {
                    '& > .MuiBox-root': {
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&.OptionItemComponentCheckbox': {
                            borderRadius: '5px',
                        },
                        '&.OptionItemComponentRadio': {
                            borderRadius: '16px',
                        },
                    },
                },
                '&.ProductCardContentContainer': {
                    width: '100%',
                    '& > .MuiBox-root': {
                        height: 'calc(350px - 32px)',
                        display: 'flex',
                        [breakpoints.down('xs')]: {
                            height: 'calc(300px - 96px)',
                        },
                    },
                },
                '&.OptionItemContentContainer': {
                    '& > .MuiBox-root': {
                        display: 'flex',
                        textAlign: 'left',
                    },
                },
                '&.SaveProgressCheckbox': {
                    paddingLeft: '48px',
                    cursor: 'pointer',
                    [breakpoints.down('xs')]: {
                        paddingLeft: 0,
                        paddingTop: '16px',
                    },
                },
                '&.SaveProgressButton': {
                    paddingLeft: '32px',
                    [breakpoints.down('xs')]: {
                        paddingLeft: 0,
                        paddingTop: '16px',
                    },
                },
            },
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
            },
        },
        MuiIconButton: {
            root: {
                '&.progressListIcon': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: 12,
                    paddingLeft: 12,
                },
                '&:AddFieldButton': {
                    ...fontStyles.Body2,
                    fontFamily: 'Roboto',
                    color: colors.color1,
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&.SaveProgress': {
                    transition: 'transform 300ms ease-in-out',
                    '&.SaveProgressOpened': {
                        transform: 'rotate(180deg)',
                    },
                },
            },
        },
        MuiInputBase: {
            root: {
                '&.SaveProgressMailInput': {
                    padding: '18.5px 0px 2.5px 7px',
                    '& input': {
                        padding: 0,
                    },
                },
            },
            input: {
                ...fontStyles.Body2,
                fontFamily: 'Roboto',
                '&.MuiInputBase-inputMarginDense': {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiInputLabel: {
            root: {
                '&$focused': {
                    color: colors.green,
                },
                '&.SaveProgressMailInput': {
                    fontSize: '0.875rem',
                },
                '&.MuiInputLabel-marginDense': {
                    transform: 'translate(0px, 0px) scale(1)',
                    fontSize: '0.875rem',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(0px, 15px) scale(0.6)',
                    },
                },
            },
            formControl: {
                left: '9px',
                '&.MuiInputLabel-marginDense': {
                    left: '6px',
                },
            },
            outlined: {
                fontSize: '1.125rem',
                [breakpoints.down('xs')]: {
                    transform: 'translate(10px, 23px) scale(1)',
                },
                color: colors.darkGray,
                '&.MuiInputLabel-marginDense': {
                    transform: 'translate(0px, 13px) scale(1)',
                    fontSize: '0.875rem',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(0px, 6px) scale(0.6)',
                    },
                },
                '&.SaveProgressMailInput': {
                    transform: 'translate(0px, 15px) scale(1)',
                },
                '&.MuiInputLabel-shrink': {
                    color: colors.darkGray,
                    transform: 'translate(12px, 10px) scale(0.75)',
                    '&.Mui-focused': {
                        color: colors.green,
                    },
                    '&.SaveProgressMailInput': {
                        transform: 'translate(0px, 6px) scale(0.75)',
                    },
                },
            },
        },
        MuiLink: {
            root: {
                fontSize: '1.25rem',
                // lineHeight: 2.125,
                '&.FooterLink': {
                    fontFamily: 'Roboto Condensed',
                },
                '&.AdminLink': {
                    fontFamily: 'Roboto Condensed',
                    fontSize: '1rem',
                },
                '&.HelpLink': {
                    // backgroundColor: colors.white,
                    color: colors.black,
                    flexDirection: 'row',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 300,
                    padding: 16,
                },
                '&.ConfigurationLink': {
                    ...fontStyles.Body1,
                    textDecoration: 'underline',
                    color: colors.color1,
                    flexDirection: 'row',
                },
            },
        },
        MuiList: {
            root: {
                '&.ProgressList, &.ProgressList > div, &.ProgressList p': {
                    transition: '250ms all',
                },
                '&.ErrorBox': {
                    padding: 20,
                    marginTop: 40,
                    width: '100%',
                    borderRadius: 4,
                    backgroundColor: colors.lightWarning,
                    '& .MuiListItem-root': {
                        padding: 0,
                        '& .MuiListItemIcon-root': {
                            color: colors.warning,
                            fontSize: '0.875rem',
                            minWidth: 16,
                            '& .MuiSvgIcon-root': {
                                height: '7px',
                                width: '7px',
                            },
                        },
                        '& .MuiListItemText-root': {
                            color: colors.warning,
                            fontSize: '0.875rem',
                            // lineHeight: 1,
                        },
                    },
                },
            },
        },
        MuiListItem: {
            root: {
                '&.progressList': {
                    paddingBottom: 0,
                    paddingTop: 0,
                    alignItems: 'center',
                },
            },
        },
        MuiListItemText: {
            root: {
                '&.progressList': {
                    marginBottom: 0,
                    marginTop: 0,
                },
            },
        },
        MuiListSubheader: {
            root: {
                '&.ErrorBoxSubheader': {
                    color: colors.warning,
                    fontSize: '1rem',
                    // lineHeight: 1.125,
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: colors.lightGray,
                border: `1px solid ${colors.lightGray}`,
                '&$focused': {
                    border: `1px solid ${colors.color2}`,
                },
                '&.TextError': {
                    border: `1px solid ${colors.warning}`,
                },
            },
            input: {
                padding: '27px 19px 10px',
                '&$disabled': {
                    backgroundColor: colors.white,
                    border: `1px solid ${colors.midGray}`,
                    borderRadius: '5px',
                    padding: '26px 18px 9px',
                },
                '&.MuiOutlinedInput-inputMarginDense': {
                    paddingTop: '18px',
                    paddingLeft: '6px',
                    paddingBottom: '4px',
                },
                '&.TextError': {
                    border: `1px solid ${colors.warning}`,
                    borderRadius: '5px',
                },
            },
            notchedOutline: {
                border: 0,
                '&$disabled': {
                    border: `1px solid ${colors.midGray}`,
                },
                '& legend': {
                    maxWidth: '0.01px',
                },
            },
        },
        MuiSelect: {
            select: {
                textAlign: 'left',
                ...fontStyles.Body2,
                fontFamily: 'Roboto',
                '&.noLabel': {
                    paddingBottom: 18,
                    paddingTop: 18,
                },
            },
        },
        MuiTextField: {
            root: {
                '&.PreviousProviderInput input': {
                    paddingBottom: 15,
                    paddingTop: 15,
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: colors.lightGray,
                borderRadius: '5px',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
                color: `${colors.black} !important`,
                padding: '24px',
                width: 200,
                ...fontStyles.subtitle1,
                textAlign: 'left',
                '& p': {
                    ...fontStyles.subtitle1,
                    textAlign: 'left',
                    marginTop: 0,
                    marginBottom: '16px',
                    color: `${colors.black} !important`,
                    '&:last-of-type': {
                        marginBottom: 0,
                    },
                },
            },
            tooltipPlacementBottom: {
                position: 'absolute',
                right: '32px',
                [breakpoints.up('xs')]: {
                    marginTop: 0,
                },
            },
        },
        MuiTouchRipple: {
            rippleVisible: {
                color: colors.darkGray,
            },
        },
        MuiTypography: {
            root: {
                '&.AddFieldButton': {
                    fontFamily: 'Roboto',
                    color: colors.color1,
                    textAlign: 'left',
                },
                '&.DesiredDateHelpText': {
                    fontFamily: 'Roboto',
                    paddingTop: 9,
                    color: colors.midGray,
                },
                '&.SwitchMonthOffer': {
                    fontWeight: 'bold',
                    color: colors.green,
                },
                '&.progressListItem': {
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontWeight: 'bold',
                    wordBreak: 'break-all',
                },
                '&.progressListSubItem': {
                    paddingTop: 5,
                    paddingBottom: 5,
                    wordBreak: 'break-all',
                },
                '&.SectionCategory': {
                    color: colors.color1,
                },
                '&.SalesPartnerText': {
                    fontSize: 18,
                    lineHeight: 1.5,
                },
                '&.ConfigurationItemTitle': {
                    color: colors.color1,
                },
                '&.ProductCategoryCardDangerously': {
                    textAlign: 'center',
                    b: {
                        textAlign: 'center',
                        ...fontStyles.UbersichtTitel,
                        fontFamily: 'Roboto',
                        fontWeight: 900,
                    },
                },
                '&.ProductCategoryCardDangerously b': {
                    ...fontStyles.UbersichtTitel,
                    fontFamily: 'Roboto',
                    fontWeight: 900,
                    textAlign: 'center',
                },
                '&.PriceDescriptionText': {
                    ...fontStyles.subtitle1,
                    color: colors.basketGray,
                },
                '&.OptionItemCategoryTitle': {
                    color: colors.black,
                },
                '&.OptionItemSubtitleLink': {
                    fontFamily: 'Roboto',
                    color: colors.black,
                    '&.OptionItemSubtitleLink a': {
                        textDecoration: 'underline',
                        color: colors.black,
                    },
                },
                '&.OptionItemSubtitle, &.OptionItemSubtitle a': {
                    color: colors.black,
                    '& p': {
                        margin: 0,
                    },
                },
                '&.ProductCardTextHighlight': {
                    textAlign: 'center',
                    ...fontStyles.Abschnittsheadline,
                    fontFamily: 'Roboto',
                    fontWeight: 900,
                },
                '&.CostsTableHeadline': {
                    fontWeight: 'bold',
                    // lineHeight: 1.4375,
                },
                '&.CostsTableSubtitle': {
                    ...fontStyles.Sternchentext,
                    color: colors.basketGray,
                },
                '&.CostsTableRow': {
                    fontFamily: 'Roboto',
                },
                '&.PriceDescriptionBasket': {
                    fontFamily: 'Roboto',
                },
                '&.OldPrice': {
                    '&::after': {
                        backgroundColor: 'red',
                        content: '" "',
                        display: 'block',
                        fontSize: '0rem',
                        height: '1px',
                        minWidth: '32px',
                        width: 'calc(100%)',
                        transform: `rotate(-10.71deg) translateY(-0.66rem)`,
                    },
                },
                '&.DataPrivacyLabel': {
                    color: colors.white,
                    '& a': { color: colors.white },
                },
                '&.ProductTableRowText': {
                    fontFamily: 'Roboto',
                },
                '&.ProductTableHeadline': {
                    fontWeight: 'bold',
                },
                '&.VZFSummaryHeadline': {
                    fontWeight: 'bold',
                },
                '&.BasketVZFLabel': {
                    fontWeight: 'bold',
                },
                '&.OrderOverviewVZFRow': {
                    fontWeight: 'bold',
                },
                '&.ProductTableAddressLink': {
                    ...fontStyles.subtitle1,
                    marginRight: '10px',
                    color: colors.basketGray,
                    textAlign: 'right',
                },
                '&.BasketTabText': {
                    ...fontStyles.ButtonText,
                },
                '&.ConfigurationHelpText': {
                    fontFamily: 'Roboto',
                    color: colors.midGray,
                    fontSize: '0.9rem',
                },
                '&.SaveProgressTitle': {
                    color: colors.white,
                },
                '&.OverviewTableText': {
                    fontFamily: 'Roboto',
                    color: colors.black,
                },
                '&.OverviewCheckboxSubheader': {
                    fontFamily: 'Roboto',
                    // lineHeight: 1.5,
                    color: colors.color1,
                },
                '&.OverviewCheckboxHelperText': {
                    fontFamily: 'Roboto',
                    color: colors.midGray,
                },
                '&.BankDetailsCheckboxLabel': {
                    fontFamily: 'Roboto',
                },
                '&.MobileProgressListItem': {
                    ...fontStyles.ButtonText,
                    fontWeight: 'bold',
                },
            },
        },
    },
    palette: {
        primary: {
            main: colors.green,
        },
        secondary: {
            dark: colors.darkGray,
            light: colors.lightGray,
            main: colors.header,
        },
        text: {
            disabled: colors.midGray,
            hint: colors.green,
            primary: colors.black,
            secondary: colors.white,
        },
    },
})

export const ModifiedTheme = responsiveFontSizes(LocalTheme)
